/****************************** components ******************************/
@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
//@import "./components/case";//例子
/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}

.center {
    width: 1200px;
    margin: 0 auto;
}

.header {
    width: 100%;
    background: #fff;
    position: relative;
    .center {
        width: 1450px;
        margin: 0 auto;
    }
    .head_top {
        height: 135px;
        .logo {
            img {
                height: 89px;
                margin-top: 24px;
                float: left;
            }
            p {
                float: left;
                font-size: 24px;
                color: #3e3a39;
                height: 60px;
                line-height: 60px;
                margin-top: 40px;
                padding-left: 20px;
                margin-left: 20px;
                border-left: 1px solid #c30d23;
            }
        }
        .fr {
            p {
                font-size: 20px;
                color: #3e3a39;
                padding-left: 30px;
                float: left;
                line-height: 135px;
                background: url(../img/phonr.png) no-repeat left center;
                span {
                    font-size: 20px;
                    color: #be0c1a;
                }
            }
            img {
                height: 124px;
                margin-top: 6px;
                margin-left: 10px;
            }

        }
    }
    .toptext {
        width: 100%;
        background: #eee;
        height: 30px;
        p {
            color: #7b7b7b;
            font-size: 14px;
            line-height: 30px;
        }
    }
}
.nav {
    background: #000;
    width: 100%;
    height: 70px;
    .center {
        width: 1200px;
        margin: 0 auto;
    }
    ul {
        li {
            float: left;
            color: #fff;
            width: 170px;
            height: 70px;
            a {
                width: 170px;
                height: 70px;
                background: #000;
                text-align: center;
                line-height: 70px;
                font-size: 16px;
                color: #fff;
                display: block;
                &:hover {
                    background: #c30d23;
                    transition: .5s;
                }
                &.active {
                    background: #c30d23;
                    transition: .5s;
                }
            }
        }
    }
}

.banner_btn {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 16px;
    overflow: hidden;
    height: 16px;
    a {
        box-shadow: inset 1px 1px 1px #333;
        width: 45px;
        height: 4px;
        display: inline-block;
        text-indent: -999px;
        overflow: hidden;
        margin: 0 3px;
        overflow: hidden;
        cursor: pointer;
        background: #fff;
        &.in {
            background: red;
        }
    }
}
.banner {
    width: 100%;
    overflow: hidden;
}
.m_banner {
    display: none;
}
.page_banner {
    width: 100%;
    overflow: hidden;
    img {
        width: 1920px;
        height: 400px;
        position: relative;
        left: 50%;
        margin-left: -960px;
    }
}
.slideBox {
    .hd {
        width: 100%;
        text-align: center;
        position: absolute;
        left: 0;
        bottom: 16px;
        overflow: hidden;
        height: 16px;
        z-index: 9;
        li {
            box-shadow: inset 1px 1px 1px #333;
            width: 45px;
            height: 4px;
            display: inline-block;
            text-indent: -999px;
            overflow: hidden;
            margin: 0 3px;
            overflow: hidden;
            cursor: pointer;
            background: #fff;
            &.on {
                background: red;
            }
        }
    }
    .bd {
        width: 1920px;
        height: 600px;
        position: relative;
        left: 50%;
        margin-left: -960px;
        li {
            img {
                width: 1920px;
                height: 600px;
            }
        }
    }
}
.main {
    width: 100%;
    overflow: hidden;
}


.box_tit {
    width: 100%;
    margin: 30px 0;
    .box_tit_cont {
        width: 660px;
        margin: 0 auto;
        p {
            display: block;
            font-size: 36px;
            color: #080103;
            font-weight: bold;
            padding-top: 10px;
            padding-left: 50px;
            background: url(../img/tit.png) no-repeat left top;
        }
        span  {
            font-size: 14px;
            background: url(../img/tit1.png) no-repeat right top;
            color: #868686;
            padding-right: 40px;
            text-align: center;
            display: block;
            height: 40px;
            line-height: 40px;
        }
    }
}
.more {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    height: 40px;
    a {
        padding: 12px 40px;
        background: #c30d23;
        color: #fff;
        font-size: 16px;
    }
}
.index_pic {
    width: 100%;
    margin: 20px 0;
    overflow: hidden;
    .pic {
        position: relative;
        width: 1920px;
        left: 50%;
        margin-left: -960px;
        img {

        }
    }
}

.i_box1 {
    .i_box1_content {
        width: 100%;
        .fl {
            width: 35%;
            height: .635rem;
            background: #000;
            padding-top: 5.3%;
            span {
                font-size: 34px;
                text-align: center;
                display: block;
                color: #fff;
            }
            strong {
                font-size: 34px;
                text-align: center;
                display: block;
                color: #fff;
            }
            p {
                font-size: 16px;
                color: #666;
                text-align: center;
                margin-top: 20px;
            }
            a {
                display: block;
                width: 162px;
                height: 40px;
                text-align: center;
                border: 1px solid #d5c99b;
                line-height: 38px;
                font-size: 14px;
                color: #d5c99b;
                margin: 0 auto;
                margin-top: 20px;
            }
        }
        .fr {
            width: 65%;
            ul {
                li {
                    width: 25%;
                    height: .635rem;
                    padding-top: 3%;
                    float: left;
                    a {
                        display: block;
                    }
                    .imgauto {
                        width: 100%;
                        height: .34rem;
                        overflow: hidden;
                    }
                    i {
                        display: block;
                        background: #fff;
                        width: 20px;
                        height: 4px;
                        margin: 10px auto;
                        margin-top: 15%;
                    }
                    span {
                        font-size: 14px;
                        color: #000;
                        font-weight: bold;
                        text-align: center;
                        display: block;
                        margin-top: 5px;
                    }
                    p {
                        text-align: center;
                        margin-top: 10px;
                        font-size: 12px;
                        color: #999;
                        margin: 0 1%;
                    }
                    &.li1 {
                        background: #faf5eb;
                        background-size: 100% 100%;
                    }
                    &.li2 {
                        background: #e6d1ca;
                        background-size: 100% 100%;
                    }
                    &.li3 {
                        background: #f2f2f2;
                        background-size: 100% 100%;
                    }
                    &.li4 {
                        background: #d1d1d1;
                        background-size: 100% 100%;
                    }
                }
            }
        }
    }
}
.i_box2 {
    width: 100%;
    margin-top: 50px;
    .i_box2_content {
        width: 100%;
        .box_tit {
            .box_tit_cont {
                width: 530px;
            }
        }
        .list {
            width: 100%;
            height: 560px;
            position: relative;
            background: url(../img/bg.png) no-repeat center;
            ul {
                position: relative;
                height: 560px;
                li {
                    width: 335px;
                    float: left;
                    position: absolute;
                    padding: 18px;
                    border: 1px solid #ccc;
                    background: url(../img/hong.png) no-repeat left top;
                    &:first-child {
                        left: 10px;
                        top: 20px;
                    }
                    &:first-child + li {
                        left: 864px;
                        top: 20px;
                    }
                    &:first-child + li +li {
                        left: 3px;
                        top: 335px;
                    }
                    &:first-child + li +li +li {
                        left: 864px;
                        top: 305px;
                    }
                    .text {
                        span {
                            font-size: 22px;
                            color: #080103;
                            text-align: center;
                            display: block;
                        }
                        p {
                            font-size: 14px;
                            margin-top: 10px;
                            line-height: 24px;
                            color: #080103;
                        }
                    }
                }
            }
        }
    }
}
.i_box3 {
    width: 100%;
    margin-top: 50px;
    background: #f3f3f3;
    padding: 40px 0;
    .i_box3_content{
        .box_tit {
            .box_tit_cont {
                width: 550px;
            }
        }
        .list {
            width: 100%;
            ul {
                li {
                    width: 100%;
                    float: left;
                    background: #cc0b1d;
                    .fl {
                        width: 50%;
                        line-height: 0;
                        img {
                            width: 100%;
                        }
                    }
                    .fr {
                        width: 50%;
                        background: #cc0b1d;
                        padding: 50px;
                        span {
                            color: #fff;
                            font-size: 18px;
                            font-weight: bold;
                            display: block;
                            border-left: 5px solid #fff;
                            padding-left: 15px;
                            line-height: 100%;
                            margin: 20px 0;
                        }
                        p {
                            color: #fff;
                            font-size: 18px;
                            line-height: 30px;
                        }
                    }
                    &.libg {
                        width: 100%;
                        background: #fff;
                        .fl {
                            float: right;
                        } 
                        .fr {
                            float: left;
                            padding: 50px;
                            background: #fff;
                            span {
                                color: #333;
                                border-left: 5px solid #333;
                            }
                            p {
                                color: #333;
                            }
                        } 
                    }
                }
            }
        }
    }
}

.i_box5 {
    padding: 40px 0;
    .i_box5_content {
        .text {
            width: 100%;
            .fl {
                width: 317px;
                height: 440px;
                background: #c30d23;
                padding: 80px 0;
                i {
                    width: 60px;
                    height: 5px;
                    background: #fff;
                    margin: 0 auto;
                    display: block;
                }
                img {
                    display: block;
                    margin: 0 auto;
                    margin-top: 50px;
                }
                span {
                    display: block;
                    color: #fff;
                    margin-top: 50px;
                    text-align: center;
                    font-size: 16px;
                }
                strong {
                    display: block;
                    color: #fff;
                    text-align: center;
                    font-size: 18px;
                    font-weight: bold;
                    margin-top: 8px;
                }
            }
            .fr {
                float: left;
                padding-top: 155px;
                ul {
                    li {
                        float: left;
                        padding-left: 60px;
                        background: url(../img/jiant.png) no-repeat 10px 20px;
                        &:first-child {
                            background: none;
                        }
                        img {
                            display: block;
                        }
                        p {
                            display: block;
                            text-align: center;
                            font-size: 16px;
                            margin-top: 10px;
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}

.i_box4 {
    .i_box4_content {
        .box_tit {
            .box_tit_cont {
                width: 390px;
            }
        }
        .list {
            width: 100%;
            margin-top: 20px;
            overflow: hidden;
            li {
                width: 31%;
                height: 100%;
                float: left;
                margin-left: 3.5%;
                position: relative;
                margin-bottom: 30px;
                &:first-child {
                    margin-left: 0;
                }
                .imgauto {
                    width: 100%;
                    img {
                        width: 100%;
                        height: 335px;
                    }
                }
                .text {
                    padding: 10px 20px;
                    width: 100%;
                    height: 100%;
                    background: #c30d23;
                    position: absolute;
                    top: -100%;
                    left: 0;
                    transition: .5s;
                    span {
                        font-size: 18px;
                        color: #fff;
                        display: block;
                        font-weight: bold;
                        text-align: center;
                        margin-top: 30px;
                    }
                    p {
                        font-size: 14px;
                        color: #fff;
                        line-height: 24px;
                        margin-top: 10px;
                    }
                }
                .jia {
                    position: absolute;
                    bottom: -23px;
                    left: 45%;
                    z-index: 9;
                    width: 45px;
                    height: 45px;
                    border-radius: 50px;
                    background: url(../img/jiab.png) no-repeat center #c30d23;
                    a {
                        float: left;
                        font-size: 14px;
                        color: #998d5d;
                    }
                }
                &:hover {
                    box-shadow: 0 0 20px #ccc;
                    .text {
                        top: 0;
                        transition: .5s;
                    }
                    .jia {
                        border: 1px solid #c30d23;
                        background: url(../img/jiah.png) no-repeat center #fff;
                    }
                }
            }
        }
    }
}

.i_box6 {
    padding-top: 50px;
    margin-top: 80px;
    border-top: 1px solid #aaa;
    .i_box6_content {
        .fl {
            width: 50%;
            padding-right: 5%;
            h3 {
                font-size: 26px;
                color: #292929;
            }
            i {
                width: 60px;
                height: 4px;
                background: #c30d23;
                display: block;
                margin: 15px 0;
            }
            span {
                font-size: 18px;
                color: #707070;
                display: block;
                border-bottom: 1px solid #ccc;
                padding-bottom: 5px;
            }
            img {
                width: 100%;
                display: block;
                margin-top: 20px;
            }
            p {
                font-size: 14px;
                color: #373737;
                line-height: 24px;
                text-indent: 2em;
                margin-top: 30px;
            }
            .amore {
                font-size: 14px;
                color: #cc0b1d;
                display: block;
                margin-top: 20px;
            }
            .new_nav {
                width: 100%;
                margin-top: 40px;
                a {
                    width: 30%;
                    float: left;
                    border: 1px solid #ccc;
                    padding: 10px 0;
                    text-align: center;
                    margin-left: 3.3%;
                    &:first-child {
                        margin-left: 0;
                        background: #cc0b1d;
                        color: #fff;
                        border: 1px solid #cc0b1d;
                    }
                    &:hover {
                        background: #cc0b1d;
                        color: #fff;
                        border: 1px solid #cc0b1d;
                    }
                }
            }
        }
        .fr {
            width: 50%;
            img {
                width: 100%;
            }
        }
    }
}

.i_box7 {
    width: 100%;
    height: 486px;
    position: relative;
    margin-top: 50px;
    .index_pic {
        position: absolute;
        margin: 0;
        z-index: 8;
        .img2 {
            display: none;
        }
    }
    .i_box7_content {
        position: relative;
        z-index: 9;
        .text {
            width: 100%;
            padding-top: 250px;
            input {
                width: 275px;
                height: 40px;
                border: none;
                border: 1px solid #333;
                border-radius: 5px;
                display: block;
                margin: 0 auto;
                margin-bottom: 20px;
                padding-left: 10px;
                outline: 0;
            }
            .btnsub {
                background: #cc0b1d;
                color: #fff;
            }

        }
    }
}


.footer {
    width: 100%;
    background: #e4e4e4;
    padding-top: 50px;
    overflow: hidden;
    .foot_nav {
        float: left;
        margin-left: 150px;
        ul {
            float: left;
            h3 {
                font-size: 18px;
                color: #404040;
                margin-bottom: 20px;
            }
            li {
                a {
                    display: block;
                    line-height: 24px;
                    font-size: 14px;
                    color: #404040;
                    margin-top: 8px;
                }
            }
        }
    }
    .foot_contact {
        h3 {
            font-size: 18px;
            color: #404040;
            margin-bottom: 20px;
        }
        span {
            font-size: 20px;
            color: #3e3a39;
            strong {
                font-size: 20px;
                color: #be0c1a;
            }
        }
        p {
            display: block;
            line-height: 24px;
            font-size: 14px;
            color: #404040;
            margin-top: 7px;
        }
    }
    .foot_ewm {
        width: 230px;
        margin-left: 50px;
        img {
            display: block;
            margin: 0 auto;
            margin-bottom: 15px;
        }
    }
}
.copyright {
    width: 100%;
    border-top: 1px solid #ccc;
    margin-top: 30px;
    p {
        height: 40px;
        line-height: 40px;
        color: #404040;
        font-size: 14px;
        text-align: center;
    }
}

.m_header {
    width: 100%;
    overflow: hidden;
    .m_head_content {
        height: .46rem;
        width: 100%;
        background: #fff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        .m_head_logo {
            margin-left: .05rem;
            height: .46rem;
            float: left;
            img {
                height: .34rem;
                margin-top: .06rem;
                float: left;
            }
            p {
                float: left;
                margin-left: .05rem;
                strong {
                    font-size: .16rem;
                    line-height: .46rem;
                    display: block;
                }
            }
        }
        .menu {
            float: right;
            width: .46rem;
            height: .46rem;
            background: #000;
            i {
                color: #fff;
                font-size: 20px;
                text-align: center;
                line-height: .46rem;
                display: block;
            }
        }
    }   
}
.app_menu {
    width: 100%;
    background: rgba(0,0,0,.5);
    position: fixed;
    height: 100%;
    top: .46rem;
    display: none;
    z-index: 99;
    ul {
        background: rgba(0,0,0,.9);
        li {
            a {
                span {
                    font-size: .14rem;
                    width: 100%;
                    height: .35rem;
                    line-height: .35rem;
                    border-bottom: 1px solid #ccc;
                    color: #fff;
                    display: block;
                    padding-left: .15rem;
                }
            }
        }
    }
}

.m_foot{
    background: #111;
    padding: 20px 0;
    display: none;
    p {
        color: #fff;
        font-size: 12px;
        text-align: center;
        line-height: 24px;
    }
}
.banner {
    width: 100%;
    height: auto;
    position: relative;
    img {
        width: 100%;
        height: auto;
        float: left;
    }
}
.m_banner {
    width: 100%;
    height: auto;
    position: relative;
    img {
        width: 100%;
        height: auto;
    }
    .bd {
        li {
            width: 100%;
            height: auto;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .hd {
        width: 100%;
        text-align: center;
        position: absolute;
        left: 0;
        bottom: 16px;
        overflow: hidden;
        height: 16px;
        z-index: 10;
        li {
            box-shadow: inset 1px 1px 1px #333;
            width: 35px;
            height: 4px;
            display: inline-block;
            text-indent: -999px;
            overflow: hidden;
            margin: 0 3px;
            overflow: hidden;
            cursor: pointer;
            background: #fff;
            &.on {
                background: red;
        }

        }
    }
}

.m_header {
    display: none;
}
.i_box4 .i_box4_content .i_box4_list li .imgauto {
    width: 100%;
    height: auto;
}
.i_box4 .i_box4_content .i_box4_list li .imgauto img {
    width: 100%;
    height: auto;
}
.iet {
    height: 30px;
    width: 100%;
    background: #fff3d7;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
    p {
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: #333;
        line-height: 30px;
        a {
            color: #3aba50;
            font-size: 12px;
        }
    }
    span {
        position: absolute;
        right: 5%;
        top: 0;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
        i {
            font-size: 12px;
            color: #333;
            padding-left: 5px;
            font-style: normal;
        }
    }
}


.i_box1_list2 {
    width: 100%;
    display: none;
    ul {
        width: 96%;
        margin: 0 2%;
        li {
            width: 25%;
            float: left;
            margin-bottom: 10px;
            a {
                display: block;
                line-height: 0;
                img {
                    width: 96%;
                    display: block;
                    margin: 0 auto;
                    line-height: 0;
                }
                p {
                    width: 96%;
                    margin: 0 auto;
                    background: #c30d23;
                    height: 40px;
                    color: #fff;
                    line-height: 40px;
                    text-align: center;
                    display: block;
                    font-size: 14px;
                }
            }
        }
    }
}

.title_menu {
    width: 100%;
    overflow: hidden;
    background: #fff;
    margin-bottom: 10px;
    box-shadow: 5px 5px 10px 5px #eee;
    .curson {
        border-bottom: 1px solid #ccc;
        width: 100%;
        .text {
            p {
                line-height: 40px;
                a {
                    font-size: 14px;
                    color: #333;
                    line-height: 40px;
                }
            }
        }
    }
    .promenu {
        ul {
            li {
                width: 16%;
                margin: 2%;
                float: left;
                height: 40px;
                box-shadow: 0px 5px 10px 0px #eee;
                a {
                    font-size: 16px;
                    text-align: center;
                    line-height: 40px;
                    display: block;
                    background: #fff;
                    &:hover {
                        background: #be0c1a;
                        color: #fff;
                        box-shadow: 0px 5px 10px 0px #be0c1a;
                    }
                    &.active {
                        background: #be0c1a;
                        box-shadow: 0px 5px 5px 0px #be0c1a;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.pro_list {
    width: 100%;
    overflow: hidden;
    ul {
        width: 105%;
        li {
            float: left;
            width: 28%;
            height: 100%;
            float: left;
            margin: 1%;
            margin-right: 3%;
            margin-bottom: 30px;
            background: #fff;
            box-shadow: 3px 5px 10px 4px #eee;
            .imgauto {
                line-height: 0;
                img {
                    width: 100%;
                }
            }
            .text {
                width: 100%;
                border-top: 1px solid #ccc;
                height: 40px;
                span {
                    width: 70%;
                    float: left;
                    font-size: 14px;
                    text-align: center;
                    color: #333;
                    height: 40px;
                    line-height: 40px;
                }
                p {
                    width: 30%;
                    float: left;
                    font-size: 14px;
                    background: #be0c1a;
                    color: #fff;
                    text-align: center;
                    height: 40px;
                    line-height: 40px;
                }
            }
        }
    }
}

.container {
    width: 100%;
    background: #f7f7f7;
    padding-bottom: 30px;
}
.cont_content {
    width: 100%;
}



.showdiv1 {
    width: 100%;
    padding: 3%;
    background: #fff;
    margin-bottom: 40px;
    .fl {
        width: 50%;
        img {
            width: 100%;
            max-width: 100%;
            display: block;
        }
    }
    .fr {
        width: 50%;
        padding-left: 4%;
        .show_tit {
            h3 {
                font-size: 26px;
                font-weight: bold;
                color: #333;
                display: block;
                margin-top: 15px;
            }
            p {
                width: 100%;
                margin-top: 15px;
                padding-bottom: 20px;
                border-bottom: 1px dashed #ccc;
                span {
                    font-size: 16px;
                    color: #333;
                    @include inline-block();
                }
                img {
                    @include inline-block();
                }
            }
        }
        .showpro_cont {
            width: 100%;
            padding: 0;
            .showprotit {
                background: #f5f5f5;
                padding-top: 0;
                margin-top: 50px;
                h4 {
                    border: none;
                }
            }
        }
    }
}

.showpro_cont {
    width: 100%;
    padding: 0 2%;
    background: #fff;
    .text{
        width: 100%;
        margin-top: 20px;
        padding-bottom: 20px;
        p {
            font-size: 16px;
            color: #333;
            line-height: 30px;  
            img {
                max-width: 100%;
                display: block;
            }
        }
    }
}
.showprotit {
    width: 100%;
    padding-top: 30px;
    h4 {
        padding-left: 40px;
        height: 42px;
        position: relative;
        line-height: 42px;
        border-bottom: 1px dashed #ccc;
        border-top: 1px solid #ccc;
        font-size: 22px;
        color: #333;
        span {
            width: 18px;
            height: 40px;
            position: absolute;
            left: 10px;
            top: -10px;
            display: block;
            background: url(../img/hong.png) no-repeat left top;
            background-size: 100%;
        }
        i {
            font-size: 22px;
            color: #eee;
            font-style: normal;
            line-height: 42px;
            margin-left: 18px;
        }
    }
}

.showpro_pages {
    width: 100%;
    margin-top: 20px;
    background: #fff;
    padding: 1% 2%;
    p {
        font-size: 14px;
        color: #666;
        line-height: 30px;
        &:first-child {
            border-bottom: 1px dashed #ccc;
        }
        a {
            font-size: 14px;
            color: #666;
        }
    }
}




.page_cont1 {
    width: 100%;
    padding: 40px 0;
    background: #fff;
    .text {
        .fl {
            width: 50%;
            padding-right: 8%;
        }
        .fr {
            width: 48%;
            .pic {
                img {
                    max-width: 100%;
                    display: block;
                }
            }
        }
    }
}
.page_cont_tit {
    width: 150px;
    height: 125px;
    background: #be0c1a;
    padding: 30px 20px;
    span {
        font-size: 18px;
        color: #fff;
        display: block;
    }
    p{
        font-size: 12px;
        color: #fff;
        margin-top: 10px;
        line-height: 20px;
    }
}
.text_cont {
    width: 100%;
    margin-top: 30px;
    p {
        font-size: 14px;
        color: #373737;
        line-height: 26px;
    }
}

.page_cont2 {
    width: 100%;
    padding-bottom: 50px;
    .text {
        .fl {
            width: 50%;
            padding-right: 8%;
            .text_cont  {
                padding-top: 5%;
            }
        }
        .fr {
            width: 50%;
            .page_cont_tit {
                float: right;
            }
        }
    }
}

.page_cont3 {
    width: 100%;
    background: #fff;
    padding: 50px 0;
    .text {
        .page_cont_tit {
            float: left;
            .text_cont  {
                padding-top: 5%;
            }
        }
        .fr {
            float: left;
            width: 76%;
            padding-top: 100px;
            p {
                float: left;
                font-size: 14px;
                color: #373737;
                line-height: 30px;
                padding-left: 5%;
            }
        }
    }
    .list {
        padding-top: 30px;
        ul {
            li {
                width: 33.3%;
                float: left;
                margin-top: 20px;
                img {
                    display: block;
                    text-align: center;
                    max-width: 100%;
                    margin: 0 auto;
                    margin-bottom: 10px;
                }
                p {
                    margin-bottom: 3px;
                    text-align: center;
                }
            }
        }
    }
}

.page_cont4 {
    width: 100%;
    background: #fff;
    overflow: hidden;
    padding-top: 50px;
    .page_cont_tit {
        margin: 0 auto;
    }
    .list {
        width: 100%;
        .menu_pic {
            width: 100%;
            margin: 30px 0;
            img {
                max-width: 100%;
                display: block;
            }
            ul {
                width: 96%;
                margin: 0 2%;
                li {
                    width: 25%;
                    float: left;
                    margin-top: 10px;
                    a {
                        width: 96%;
                        display: block;
                        background: #acacac;
                        color: #fff;
                        padding: 8px 0;
                        font-size: 14px;
                        text-align: center;
                        &.active {
                            background: #be0c1a;
                            color: #fff;
                        }
                    }
                }
            }
        }
        
    }
}
.picScroll-left {
    width: 96%;
    background: #fff;
    margin: 2%;
    box-shadow: 0 0 20px #ccc;
    padding: 40px 0;
    position: relative;
    .hd {
        a {
            position: absolute;
            width: 44px;
            height: 44px;
            overflow: hidden;
            cursor: pointer;
        }
        .next {
            background: url(../img/le.png) no-repeat center;
            left: 1px;
            top: 42%;
        }
        .prev {
            background: url(../img/ri.png) no-repeat center;
            right: 1px;
            top: 42%;
        }
    }
    .bd {
        width: 90%;
        margin: 0 5%;
        ul {
            li {
                width: 20%;
                float: left;
                .text {
                    width: 96%;
                    margin: 0 2%;
                    .pic {
                        width: 100%;
                        img {
                            width: 100%;
                        }
                    }
                    .title {
                        width: 100%;
                        a {
                            display: block;
                            font-size: 14px;
                            color: #333;
                            line-height: 30px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

.script3 {
    display: none;
}
.script1 {
    display: none;
}



.new_list {
    width: 100%;
    background: #fff;
    padding: 30px 0;
    ul {
        width: 100%;
        li {
            width: 96%;
            margin: 0 2%;
            float: left;
            margin-bottom: 20px;
            .li_cont {
                width: 100%;
                .time {
                    width: 10%;
                    float: left;
                    span {
                        height: 55px;
                        display: block;
                        text-align: center;
                        line-height: 55px;
                        color: #fff;
                        font-size: 30px;
                        background: #be0c1a;
                    }
                    p {
                        height: 42px;
                        display: block;
                        text-align: center;
                        line-height: 42px;
                        color: #fff;
                        font-size: 20px;
                        background: #c1c1c1;
                    }
                }
                .pic {
                    width: 22%;
                    float: left;
                    margin: 0 2%;
                    img {
                        width: 100%;
                    }
                }
                .text {
                    width: 64%;
                    float: left;
                    span {
                        font-size: 20px;
                        color: #000;
                    }
                    p {
                        font-size: 14px;
                        color: #888;
                        line-height: 26px;
                        margin-top: 15px;
                    }
                    .more {
                        background: #be0c1a;
                        color: #fff;
                        font-size: 14px;
                        float: left;
                        width: 120px;
                        margin-top: 2%;
                        height: 34px;
                        line-height: 34px;
                    }
                }
            }
        }
    }
}



.new_cont {
    width: 100%;
    background: #fff;
    padding: 2%;
}
.new_time {
    width: 100%;
    padding-bottom: 8px;
    border-bottom: 1px solid #a5a5a5;
    p {
        float: left;
        font-size: 14px;
        color: #a5a5a5;
    }
    span {
        float: right;
        font-size: 14px;
        color: #a5a5a5;
    }
}
.content_tit {
    width: 100%;
    padding-top: 30px;
    h3 {
        font-size: 20px;
        color: #000;
        display: block;
        text-align: center;
        padding-bottom: 16px;
        background: url(../img/art.png) no-repeat bottom center;
    }
}
.content {
    width: 100%;
    p {
        font-size: 14px;
        color: #333;
        line-height: 26px;
        margin-top: 10px;
    }
}



.page_contact {
    width: 100%;
    padding: 2%;
    .text1 {
        width: 100%;
        padding-bottom: 80px;
        border-bottom: 1px dashed #aaa;
        p {
            font-size: 16px;
            color: 3333;
            display: block;
            text-align: center;
        }
        img {
            max-width: 100%;
            display: block;
            margin: 0 auto;
            margin-top: 20px;
            padding: 10px;
            background: #fff;
            box-shadow: 4px 5px 10px 2px #aaa;
        }
    } 
    .text2 {
        width: 36%;
        margin: 0 auto;
        padding-top: 50px;
        p {
            font-size: 16px;
            color: 3333;
            display: block;
            text-align: center;
            margin-bottom: 30px;
        }
        input {
            width: 100%;
            height: 35px;
            border: none;
            background: #fff;
            margin-bottom: 15px;
            outline: 0;
            box-shadow: 4px 5px 10px 2px #aaa;
            padding-left: 10px;
        }
        textarea {
            width: 100%;
            height: 105px;
            border: none;
            background: #fff;
            margin-bottom: 15px;
            outline: 0;
            box-shadow: 4px 5px 10px 2px #aaa;
            padding: 10px;
        }
        .subbtn {
            width: 100%;
            background: #f2f2f2;
        }
    }     
}
.map {
    width: 100%;
    padding-top: 50px;
    margin-top: 50px;
    border-top: 1px solid #ccc;
    .text {
        width: 100%;
        p {
            font-size: 20px;
            color: #404040;
            display: block;
            text-align: center;
            padding-bottom: 24px;
            background: url(../img/art2.png) no-repeat bottom center;
        }
        span {
            display: block;
            margin-top: 10px;
            text-align: center;
            font-size: 18px;
            color: #989898;
        }
    }
} 

.contact_banner {
    .title_menu {
        margin-bottom: 0;
    }
}


.page_banner {
    width: 100%;
    overflow: hidden;
    position: relative;
    .pic {
        img {
            width: 1920px;
            height: 622px;
            position: relative;
            left: 50%;
            margin-left: -960px;
        }
    }
    .text {
        width: 100%;
        position: absolute;
        top: 0;
        .page_cont_tit {
            margin-left: 15%;
            margin-top: 40px;
        }
        .text_list {
            margin-left: 15%;
            margin-top: 40px;
            background: url(../img/hbg.png);
            width: 383px;
            height: 281px;
            padding: 1%;
            .contact_text {
                width: 100%;
                height: 100%;
                padding: 5% 15%;
                border: 1px solid #aaa;
                p {
                    font-size: 14px;
                    color: #fff;
                    padding-left: 60px;
                    height: 50px;
                    margin-top: 20px;
                }
                .p1 {
                    padding-top: 6px;
                    background: url(../img/a1.png) no-repeat left center;
                }
                .p2 {
                    background: url(../img/a2.png) no-repeat left center;
                    line-height: 50px;
                }
                .p3 {
                    background: url(../img/a3.png) no-repeat left center;
                    line-height: 50px;
                }
            }
        }
    }
}



.case_list {
    ul {
        li {
            a {
                .text {
                    p {
                        width: 100%;
                    }
                }
            }
        }
    }
}














@media screen and (max-width: 1450px){
    .header .center {
        width: 100%;
    }
}



@media screen and (max-width: 1400px){ 
    .center {
        width: 1200px;
        margin: 0 auto;
    }
    .i_box1 .i_box1_content .fl {
        height: .7rem;
    }
    .i_box1 .i_box1_content .fr ul li {
        height: .7rem;
    }

    .header .center {
        width: 100%;
    }
    .index_pic {
        width: 100%;
        margin: 20px 0;
        overflow: hidden;
        .pic {
            position: relative;
            width: 100%;
            left: 0;
            margin-left: 0;
            img {
               width: 100%;
               display: block; 
            }
        }
    }

    .i_box7 {
        .index_pic {
            position: absolute;
            margin: 0;
            z-index: 8;
            height: 100%;
            img {
                width: 1920px;
                height: 100%;
                position: relative;
                left: 50%;
                margin-left: -960px;
            }
        }
    }

    .page_banner {
        width: 100%;
        overflow: hidden;
        img {
            width: 100%;
            height: auto;
            position: relative;
            left: 0;
            margin-left: 0;
        }
    }
}

@media screen and (max-width: 1180px){

    .center {
        width: 100%;
    }
    .nav ul li .nav_cont .fl .nav_a {
        margin-right: 50px;
    }
    .i_box2 .i_box2_content .fr strong {
        font-size: 30px;
    }
    .i_box2 .i_box2_content .fr p {
        margin-top: 8px;
    }
    .m_header {
        display: none;
    }
    .header {
        display: block;
    }
    .footer .foot_nav {
        margin-right: 50px;
    }

    .i_box1_list {
        display: none;
    }

    .i_box1_list2 {
        display: block;
    }

    .i_box2 {
        display: none;
    }
    .title_menu .curson .text p {
        padding: 0 2%;
    }
    .page_cont1 {
        width: 100%;
        padding: 30px 2%;
    }


    .page_contact {
        width: 100%;
        padding: 2%;
        .text1 {
            padding-bottom: 80px;
            img {
                margin-top: 20px;
            }
        } 
        .text2 {
            width: 40%;
            margin: 0 auto;
            padding-top: 50px;
            p {
                margin-bottom: 30px;
            }
        }     
    }


    .map {
        .text {
            width: 100%;
            padding: 0 2%;
        }
    }
}

@media screen and (max-width: 1020px){
    .m_header {
        display: block;
    }
    .header {
        display: none;
    }
    .m_header .m_head_content {
        height: 80px;
    }
    .m_header .m_head_content .m_head_logo {
        height: 80px;
    }
    .m_header .m_head_content .m_head_logo img {
        height: 50px;
        margin-top: 15px;
    }
    .m_header .m_head_content .menu {
        height: 80px;
        width: 80px;
    }
    .m_header .m_head_content .menu i {
        height: 80px;
        line-height: 80px;
    }
    .app_menu {
        top: 80px;
    }
    .app_menu ul li a span {
        font-size: 14px;
        height: 42px;
        line-height: 42px;
    }
    .banner {
        padding-top: 80px;
    }
    .i_box1 .i_box1_content .fr ul li {
        height: .9rem;
    }
    
    .page_banner {
        padding-top: 80px;
    }

    .i_box3 {
        .i_box3_content{
            .list {
                ul {
                    li {
                        .fr {
                            padding: 3%;
                        }
                        &.libg {
                            .fr {
                                padding: 3%;
                            }
                        }
                    }
                }
            }
        }
    }


    .i_box5 {
        padding: 20px 0;
        .i_box5_content {
            .text {
                width: 100%;
                .fl {
                    width: 30%;
                    height: 30%;
                    background: #c30d23;
                    padding: 80px 0;
                }
                .fr {
                    float: left;
                    width: 70%;
                    padding-top: 12%;
                    padding-left: 4%;
                    ul {
                        li {
                            &:first-child {
                                background: none;
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    
    .i_box6 {
        padding-top: 50px;
        margin-top: 80px;
        border-top: 1px solid #aaa;
        .i_box6_content {
            .fl {
                width: 50%;
                padding-right: 5%;
                h3 {
                    font-size: 26px;
                    color: #292929;
                }
                i {
                    width: 60px;
                    height: 4px;
                    background: #c30d23;
                    display: block;
                    margin: 15px 0;
                }
                span {
                    font-size: 18px;
                    color: #707070;
                    display: block;
                    border-bottom: 1px solid #ccc;
                    padding-bottom: 5px;
                }
                img {
                    width: 100%;
                    display: block;
                    margin-top: 20px;
                }
                p {
                    font-size: 14px;
                    color: #373737;
                    line-height: 24px;
                    text-indent: 2em;
                    margin-top: 30px;
                }
                .amore {
                    font-size: 14px;
                    color: #cc0b1d;
                    display: block;
                    margin-top: 20px;
                }
                .new_nav {
                    width: 100%;
                    margin-top: 40px;
                    a {
                        width: 30%;
                        float: left;
                        border: 1px solid #ccc;
                        padding: 10px 0;
                        text-align: center;
                        margin-left: 3.3%;
                        &:first-child {
                            margin-left: 0;
                            background: #cc0b1d;
                            color: #fff;
                            border: 1px solid #cc0b1d;
                        }
                        &:hover {
                            background: #cc0b1d;
                            color: #fff;
                            border: 1px solid #cc0b1d;
                        }
                    }
                }
            }
            .fr {
                width: 50%;
                img {
                    width: 100%;
                }
            }
        }
    }

    

    .footer {
        width: 100%;
        background: #e4e4e4;
        padding-top: 50px;
        overflow: hidden;
        .foot_nav {
            float: left;
            margin-left: 6%;
            margin-right: 0;
        }
        .foot_ewm {
            margin-left: 2%;
        }
    }


    .page_contact {
        width: 100%;
        padding: 2%;
        .text1 {
            padding-bottom: 80px;
            img {
                margin-top: 20px;
            }
        } 
        .text2 {
            width: 45%;
            margin: 0 auto;
            padding-top: 50px;
            p {
                margin-bottom: 30px;
            }
        }     
    }

    .contact_banner {
        padding-top: 80px;
        .title_menu {
            display: none;
            margin-bottom: 0;
        }
        .page_banner {
            padding-top: 0;
        }
    }



}

@media screen and (max-width: 900px){
    .nav ul li .nav_cont .fl .nav_a {
        margin-right: 20px;
    }
    .i_box2 .i_box2_content .fr strong {
        font-size: 24px;
    }
    .i_box2 .i_box2_content .fr span {
        font-size: 16px;
    }
    .i_box3 .i_box3_content ul li {
        width: 50%;
    }
    .i_box4 .i_box4_content .i_box4_tit {
        margin-left: 1%;
    }
    
    .footer {
        .foot {
            width: 98%;
            margin: 0 1%;
        }
    }
    .footer {
        width: 100%;
        background: #e4e4e4;
        padding-top: 50px;
        overflow: hidden;
        .foot_nav {
            float: left;
            margin-left: 2%;
            margin-right: 0;
        }
        .foot_ewm {
            margin-left: 2%;
        }
    }


    .i_box3 {
        .i_box3_content{
            .list {
                width: 100%;
                ul {
                    li {
                        .fr {
                            padding: 1%;
                            span {
                                line-height: .12rem;
                                font-size: .08rem;
                            }
                            p {
                                font-size: .06rem;
                                line-height: .12rem;
                            }
                        }
                        &.libg {
                            .fr {
                                padding: 1%;
                                span {
                                    font-size: .08rem;
                                    line-height: .12rem;
                                }
                                p {
                                    font-size: .06rem;
                                    line-height: .12rem;
                                }
                            } 
                        }
                    }
                }
            }
        }
    }

    .i_box6 {
        padding-top: 30px;
        margin-top: 50px;
        border-top: 1px solid #aaa;
        .i_box6_content {
            .fl {
                width: 100%;
                padding: 0 2%;
                p {
                    margin-top: 15px;
                }
                .amore {
                    margin-top: 15px;
                }
                .new_nav {
                    margin-top: 25px;
                }
            }
            .fr {
                width: 50%;
                display: none;
                img {
                    width: 100%;
                }
            }
        }
    }

    .script5 {
        display: none;
    }

    .script3 {
        display: block;
    }
    .picScroll-left {
        .bd {
            width: 88%;
            margin: 0 6%;
            ul {
                li {
                    width: 33.33%;
                    float: left;
                    .text {
                        width: 96%;
                        margin: 0 2%;
                        .pic {
                            width: 100%;
                            img {
                                width: 100%;
                            }
                        }
                        .title {
                            width: 100%;
                            a {
                                display: block;
                                font-size: 14px;
                                color: #333;
                                line-height: 30px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }


    .new_list {
        ul {
            li {
                .li_cont {
                    .time {
                        display: none;
                    }
                    .pic {
                        width: 32%;
                        float: left;
                        margin: 0 2%;
                        img {
                            width: 100%;
                        }
                    }
                    .text {
                        width: 64%;
                        float: left;
                        span {
                            font-size: 18px;
                            color: #000;
                        }
                        p {
                            font-size: 14px;
                            color: #888;
                            line-height: 24px;
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
    .page_contact {
        width: 100%;
        padding: 2%;
        .text1 {
            padding-bottom: 80px;
            img {
                margin-top: 20px;
            }
        } 
        .text2 {
            width: 50%;
            margin: 0 auto;
            padding-top: 50px;
            p {
                margin-bottom: 30px;
            }
        }     
    }


}




@media screen and (max-width: 850px) {
    .showdiv1 {
        .fl {
            width: 100%;
        }
        .fr {
            width: 100%;
            padding-left: 0%;
            .showpro_cont {
                .showprotit {
                    margin-top: 20px;
                    h4 {
                        border: none;
                    }
                }
            }
        }
    }
}



@media screen and (max-width: 800px){
    .i_box2 .i_box2_content .fl {
        width: 94%;
        margin: 0 3%;
    }
    .i_box2 .i_box2_content .fr {
        width: 94%;
        margin: 0 3%;
    }
    .i_box4 .i_box4_content .i_box4_list li {
        width: 48%;
        margin-left: 0;
        margin: 1%;
    }
    .i_box4 .i_box4_content .i_box4_list li:first-child {
        margin-left: 1%;
    }



    .banner {
        padding-top: 80px;
    }


    .i_box4 {
        .i_box4_content {
            .box_tit {
                .box_tit_cont {
                    width: 390px;
                    p {
                        text-align: center;
                    }
                    span {
                        text-align: center;
                    }
                }
            }
            .list {
                width: 100%;
                margin-top: 20px;
                overflow: hidden;
                li {
                    width: 47%;
                    margin-left: 0;
                    margin-right: 3%;
                    margin-bottom: 30px;
                    .text {
                        display: none;
                    }
                }
            }
        }
    }

    .footer {
        width: 100%;
        background: #e4e4e4;
        padding-top: 50px;
        overflow: hidden;
        .foot_nav {
            float: left;
            margin-left: 2%;
            margin-right: 0;
            display: none;
        }
        .foot_ewm {
            width: 28%;
            margin-left: 2%;
            img {
                max-width: 100%;
            }
        }
    }
    
    .pro_list {
        width: 96%;
        margin: 0 2%;
        overflow: hidden;
        ul {
            width: 100%;
            li {
                float: left;
                width: 48%;
                height: 100%;
                float: left;
                margin-right: 0%;
                margin: 1%;
                margin-bottom: 30px;
                background: #fff;
                box-shadow: 3px 5px 10px 4px #eee;
                .imgauto {}
            }
        }
    }

    .page_cont1 {
        padding: 20px 2%;
        .text {
            .fl {
                width: 100%;
                padding-right: 0;
                .page_cont_tit {
                    margin: 0 auto;
                }
                .text_cont {
                    margin-top: 10px;
                }
            }
            .fr {
                width: 100%;
                .pic {
                    img {
                        max-width: 100%;
                        display: block;
                        margin: 10px auto;
                    }
                }
            }
        }
    }
    .page_cont2 {
        padding: 20px 2%;
        .text {
            .fl {
                width: 100%;
                padding-right: 0;
                .text_cont {
                    padding-top: 10px;
                    margin-top: 5px;
                }
            }
            .fr {
                width: 100%;
                .page_cont_tit {
                    float: none;
                    margin: 0 auto;
                }
                .text_cont {
                    margin-top: 10px;
                }
            }
        }
    }
    
    .page_cont3 {
        padding: 20px 2%;
        .text {
            .page_cont_tit {
                float: none;
                margin: 0 auto;
            }
            .fr {
                width: 100%;
                padding-top: 10px;
                p {
                    width: 100%;
                    padding-left: 0;
                }
            }
        }
        .list {
            padding-top: 10px;
        }
    }

    .new_list {
        ul {
            li {
                width: 48%;
                float: left;
                margin: 0 1%;
                margin-bottom: 20px;
                .li_cont {
                    .time {
                        display: none;
                    }
                    .pic {
                        width: 100%;
                        float: left;
                        margin: 0;
                        img {
                            width: 100%;
                        }
                    }
                    .text {
                        width: 100%;
                        float: left;
                        span {
                            font-size: 18px;
                            margin-top: 10px;
                            color: #000;
                            display: block;
                        }
                        p {
                            font-size: 14px;
                            color: #888;
                            line-height: 24px;
                            margin-top: 8px;
                        }
                    }
                }
            }
        }
    }




}

@media screen and (max-width: 730px){
    .i_box1 .i_box1_content .fr ul li {
        width: 50%;
        height: 1.5rem;

    }
    .i_box1 .i_box1_content .fr ul li .imgauto {
        height: 1rem;
    }
    .i_box1 .i_box1_content .fr ul li i {
        margin-top: 6%;
    }
    .i_box2 .i_box2_content .fr a {
        margin-top: 10px;
        height: 32px;
        line-height: 30px;
    }

    .i_box1_list2 {
        ul {
            li {
                width: 50%;
            }
        }
    }
    .box_tit {
        width: 100%;
        margin: 30px 0;
        .box_tit_cont {
            width: 96%;
            margin: 0 auto;
            p {
                font-size: .15rem;
            }
            span  {
                font-size: .06rem;
            }
        }
    }
    .more {
        width: 100%;
        text-align: center;
        margin-top: 20px;
        a {
            padding: 12px 40px;
            background: #c30d23;
            color: #fff;
            font-size: .06rem;
        }
    }


    .i_box2 {
        width: 100%;
        margin-top: 10px;
        display: block;
        .i_box2_content {
            width: 100%;
            .box_tit {
                .box_tit_cont {
                    width: 96%;
                    margin: 0 auto;
                }
            }
            .list {
                width: 96%;
                margin: 0 2%;
                height: auto;
                position: relative;
                background: none;
                ul {
                    position: relative;
                    height: auto;
                    li {
                        width: 98%;
                        float: none;
                        position: inherit;
                        margin-bottom: 15px;
                        padding: 2%;
                        border: 1px solid #ccc;
                        background: url(../img/hong.png) no-repeat left top;
                        &:first-child {
                            left: 0;
                            top: 0;
                        }
                        &:first-child + li {
                            left: 0;
                            top: 0;
                        }
                        &:first-child + li +li {
                            left: 0;
                            top: 0;
                        }
                        &:first-child + li +li +li {
                            left: 0;
                            top: 0;
                        }
                        .text {
                            span {
                                font-size: 22px;
                                color: #080103;
                                text-align: left;
                                display: block;
                                padding-left: 25px;
                            }
                            p {
                                font-size: 14px;
                                margin-top: 10px;
                                line-height: 24px;
                                color: #080103;
                                padding-left: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .i_box3 {
        padding-top: 20px;
        margin-top: 0;
        .i_box3_content{
            .box_tit {
                margin: 10px 0;
                .box_tit_cont {
                    width: 96%;
                    margin: 0 auto;
                    p {
                        text-align: center;
                    }
                    span {
                        text-align: center;
                    }
                }
            }
        }
    }

    .i_box7 {
        .index_pic {
            position: absolute;
            margin: 0;
            z-index: 8;
            height: 100%;
            .pic {
                height: 100%;
            }
            .img1 {
                display: none;
            }
            .img2 {
                width: 100%;
                height: 100%;
                display: block;
                position: relative;
                left: 0;
                margin-left: 0px;
            }
        }
    }

    .showprotit {
        h4 {
            font-size: 18px;
            span {
            }
            i {
                font-size: 16px;
            }
        }
    }

    .page_contact {
        width: 100%;
        padding: 2%;
        .text1 {
            padding-bottom: 80px;
            img {
                margin-top: 20px;
            }
        } 
        .text2 {
            width: 55%;
            margin: 0 auto;
            padding-top: 50px;
            p {
                margin-bottom: 30px;
            }
        }     
    }

    
}




@media screen and (max-width: 650px){
    .i_box3 {
        .i_box3_content{
            .list {
                width: 100%;
                ul {
                    li {
                        .fr {
                            width: 100%;
                            padding: 0;
                            padding: 15px 2%;
                            span {
                                font-size: 16px;
                                line-height: 24px;
                                margin: 8px 0;
                            }
                            p {
                                font-size: 14px;
                                line-height: 24px;
                            }
                        }
                        .fl {
                            width: 100%;
                        }
                        &.libg {
                            .fl {
                                width: 100%;
                                padding: 0;
                            } 
                            .fr {
                                width: 100%;
                                padding: 15px 2%;
                                span {
                                    font-size: 16px;
                                    line-height: 24px;
                                    margin: 8px 0;
                                }
                                p {
                                    font-size: 14px;
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    
    .i_box5 {
        padding: 20px 0;
        .i_box5_content {
            .text {
                width: 100%;
                .fl {
                    width: 40%;
                    height: 30%;
                    background: #c30d23;
                    padding: 80px 0;
                }
                .fr {
                    float: left;
                    width: 60%;
                    padding-top: 6%;
                    padding-left: 4%;
                    ul {
                        li {
                            &:first-child {
                                background: none;
                                padding-left: 60px;
                            }
                        }
                    }
                }
            }
        }
    }
    .title_menu .promenu ul li {
        margin-bottom: 4%;
    }
    .title_menu .promenu ul li a {
        font-size: .08rem;
    }




}

@media screen and (max-width: 585px){
    .i_box3 .i_box3_content ul li {
        width: 100%;
    }
    .i_box4 .i_box4_content .i_box4_list li {
        width: 98%;
        margin-left: 0;
        margin: 1%;
    }
    .i_box4 .i_box4_content .i_box4_list li:first-child {
        margin-left: 1%;
    }
    .i_box4 .i_box4_content .i_box4_list li .time {
        display: none;
    }

    .m_header {
        width: 100%;
        overflow: hidden;
        .m_head_content {
            height: .46rem;
            width: 100%;
            background: #fff;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 99;
            .m_head_logo {
                margin-left: .05rem;
                height: .46rem;
                float: left;
                img {
                    height: .34rem;
                    margin-top: .06rem;
                    float: left;
                }
                p {
                    float: left;
                    margin-left: .05rem;
                    strong {
                        font-size: .16rem;
                        line-height: .46rem;
                        display: block;
                    }
                }
            }
            .menu {
                float: right;
                width: .46rem;
                height: .46rem;
                background: #000;
                i {
                    color: #fff;
                    font-size: 20px;
                    text-align: center;
                    line-height: .46rem;
                    display: block;
                }
            }
        }   
    }
    .app_menu {
        width: 100%;
        background: rgba(0,0,0,.5);
        position: fixed;
        height: 100%;
        top: .46rem;
        display: none;
        z-index: 99;
        ul {
            background: rgba(0,0,0,.9);
            li {
                a {
                    span {
                        font-size: .14rem;
                        width: 100%;
                        height: .35rem;
                        line-height: .35rem;
                        border-bottom: 1px solid #ccc;
                        color: #fff;
                        display: block;
                        padding-left: .15rem;
                    }
                }
            }
        }
    }
    .banner {
        padding-top: .46rem;
    }
    .page_banner {
        padding-top: .46rem;
    }

    .contact_banner {
        padding-top: .46rem;
        .title_menu {
            display: none;
            margin-bottom: 0;
        }
        .page_banner {
            padding-top: 0;
            .page_cont_tit {
                margin: 0 auto;
                margin-top: 40px;
            }
            .text_list {
                width: 90%;
                margin: 0 auto;
                margin-top: 40px;
            }
        }
    }

    .i_box1 .i_box1_content .fr ul li {
        width: 50%;
        height: 1.7rem;
    }

    .footer {
        width: 100%;
        background: #e4e4e4;
        padding-top: 50px;
        overflow: hidden;
        .foot_contact {
            float: left;
            margin-left: 2%;
            margin-right: 0;
            width: 96%;
            margin: 0 2%;
        }
        .foot_ewm {
            width: 96%;
            margin: 0 2%;
            .img1 {
                display: none;
            }
            img {
                max-width: 100%;
                text-align: left;
                margin-left: 0;
            }
        }
        .copyright {
            width: 96%;
            margin: 0 2%;
        }
    }

    .page_contact {
        width: 100%;
        padding: 2%;
        .text1 {
            padding-bottom: 80px;
            img {
                margin-top: 20px;
            }
        } 
        .text2 {
            width: 65%;
            margin: 0 auto;
            padding-top: 50px;
            p {
                margin-bottom: 30px;
            }
        }     
    }


}

@media screen and (max-width: 550px) {
    // .m_foot {
    //     display: block;
    // }
    // .footer {
    //     display: none;
    // }
    .copyright p {
        text-align: center;
        height: auto;
        padding: 10px 0;
        line-height: 24px;
    }
    .i_box4 .i_box4_content .i_box4_list li .imgauto {
        width: 100%;
        height: auto;
    }
    .i_box4 .i_box4_content .i_box4_list li .imgauto img {
        width: 100%;
        height: auto;
    }
    .i_box1 .i_box1_content .fr ul li {
        width: 50%;
        height: 2rem;
    }
    .banner_btn{
        bottom: 5px;
    }
    .banner_btn a {
        width: 30px;
        height: 3px;
    }

    .box_tit {
        width: 100%;
        margin: 10px 0;
        .box_tit_cont {
            width: 96%;
            margin: 0 auto;
            p {
                font-size: .18rem;
                background: none;
                padding-left: 0;
                text-align: center;
            }
            span  {
                font-size: .06rem;
                background: none;
                padding-right: 0;
                line-height: 140%;
            }
        }
    }

    .showprotit {
        h4 {
            font-size: 18px;
            span {
            }
            i {
                font-size: 12px;
                margin-left: 18px;
                display: none;
            }
        }
    }
    .showpro_cont .text p {
        font-size: 14px;
        color: #333;
        line-height: 30px;
    }

    .script3 {
        display: none;
    }
    .script1 {
        display: block;
    }

    .picScroll-left {
        .bd {
            width: 88%;
            margin: 0 6%;
            ul {
                li {
                    width: 100%;
                    float: left;
                    .text {
                        width: 92%;
                        margin: 0 4%;
                        .pic {
                            width: 100%;
                            img {
                                width: 100%;
                            }
                        }
                        .title {
                            width: 100%;
                            a {
                                display: block;
                                font-size: 14px;
                                color: #333;
                                line-height: 30px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }

    .new_list {
        ul {
            li {
                width: 98%;
                float: left;
                margin-bottom: 20px;
                .li_cont {
                    .time {
                        display: none;
                    }
                    .pic {
                        width: 100%;
                        float: left;
                        margin: 0;
                        img {
                            width: 100%;
                        }
                    }
                    .text {
                        width: 100%;
                        float: left;
                        span {
                            font-size: 18px;
                            margin-top: 10px;
                            color: #000;
                            display: block;
                        }
                        p {
                            font-size: 14px;
                            color: #888;
                            line-height: 24px;
                            margin-top: 8px;
                        }
                    }
                }
            }
        }
    }
    .page_contact {
        width: 100%;
        padding: 2%;
        .text1 {
            padding-bottom: 40px;
            img {
                margin-top: 20px;
            }
        } 
        .text2 {
            width: 90%;
            margin: 0 auto;
            padding-top: 30px;
            p {
                margin-bottom: 30px;
            }
        }     
    }


}



@media screen and (max-width: 500px) {
    .i_box4 {
        .i_box4_content {
            .box_tit {
                .box_tit_cont {
                    width: 390px;
                }
            }
            .list {
                width: 100%;
                margin-top: 20px;
                overflow: hidden;
                li {
                    width: 96%;
                    margin: 0 2%;
                    margin-bottom: 30px;
                    &:first-child {
                        margin-left: 2%;
                    }
                    .text {
                        display: none;
                    }
                }
            }
        }
    }

    .i_box5 {
        padding: 20px 0;
        .i_box5_content {
            .text {
                width: 100%;
                .fl {
                    width: 40%;
                    height: 30%;
                    background: #c30d23;
                    padding: 80px 0;
                    span {
                        font-size: 14px;
                    }
                    strong {
                        font-size: 14px;
                    }
                }
                .fr {
                    float: left;
                    width: 60%;
                    padding-top: 8%;
                    padding-left: 0%;
                    ul {
                        li {
                            padding-left: 15%;
                            background-size: 20%;
                            &:first-child {
                                background: none;
                                padding-left: 15%;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 450px) {

    
    .title_menu {
        .curson {
            width: 100%;
            padding: 0 2%;
        }
        .promenu {
            ul {
                li {
                    width: 21%;
                    margin: 2%;
                    float: left;
                    height: 40px;
                    box-shadow: 0px 5px 10px 0px #eee;
                    a {
                        font-size: 13px;
                        text-align: center;
                        line-height: 40px;
                        display: block;
                        background: #fff;
                        &:hover {
                            background: #be0c1a;
                            color: #fff;
                            box-shadow: 0px 0px 0px 0px #eee;
                        }
                        &.active {
                            background: #be0c1a;
                            box-shadow: 0px 0px 0px 0px #eee;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    .pro_list {
        ul {
            width: 100%;
            li {
                float: left;
                width: 98%;
                height: 100%;
                float: left;
                margin-right: 0%;
                margin: 1%;
                margin-bottom: 30px;
                background: #fff;
                box-shadow: 3px 5px 10px 0px #eee;
            }
        }
    }
    

    .contact_banner {
        .page_banner {
            .text_list {
                .contact_text {
                    padding: 5%;
                }
            }
        }
    }

    
}

@media screen and (max-width: 350px) {

    .contact_banner {
        .page_banner {
            .text_list {
                .contact_text {
                    padding: 5% 2%;
                }
            }
        }
    }


    .i_box5 {
        padding: 20px 0;
        .i_box5_content {
            .text {
                width: 100%;
                .fl {
                    width: 40%;
                    height: 30%;
                    background: #c30d23;
                    padding: 80px 0;
                    span {
                        font-size: 14px;
                    }
                    strong {
                        font-size: 14px;
                    }
                }
                .fr {
                    float: left;
                    width: 60%;
                    padding-top: 8%;
                    padding-left: 0%;
                    ul {
                        li {
                            width: 50%;
                            background: none;
                            margin-bottom: 10px;
                            text-align: center;
                            padding-left: 0;
                            img {
                                display: block;
                                margin: 0 auto;
                            }
                            &:first-child {
                                background: none;
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }


    .footer {
        .foot_contact {
            span {
                font-size: 16px;
                strong {
                    font-size: 16px;
                }
            }
        }
    }
}
/****************************** media ******************************/